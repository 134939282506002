$(document).ready(function() {
	// object fit images
    if ( $('.js-object-fit-img').length ) {
        var $objectFitImg = $('.js-object-fit-img');
        objectFitImages($objectFitImg);
    }

    
    if ( $('.js-homepage-slider').length ) {
        $('.js-homepage-slider').each(function(){
            $(this).slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                draggable: false,
                autoplay: true,
                autoplaySpeed: 3000,
                pauseOnFocus: true
            });
        });
    }

    if ( $('.js-plan-slider-container').length ) {
        $('.js-plan-slider-container').each(function(){
            $(this).find('.js-plan-slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                draggable: false,
                speed: 500,
                fade: true,
                dots: true,
                arrows: true,
                adaptiveHeight: true,
                initialSlide: 2,
                appendDots: $(this).find('.js-plan-slider-dots'),
                prevArrow: $(this).find('.js-plan-slider-prev'),
                nextArrow: $(this).find('.js-plan-slider-next')
            });
        });
    }

    // Table responsive
    if ( $('table').length ) {
        $('table').each(function() {
            var table = $(this),
                tableBody = table.find('tbody'),
                tableHeading = table.find('th');


            tableBody.find('tr').each(function() {
                var tableRow = $(this);
                tableRow.find('td').each(function() {
                    var tableCell = $(this),
                        tableCellIndex = tableCell.index();
                        
                    tableCell.attr('data-type', '' + tableHeading.eq(tableCellIndex).text() + '');
                });
            });
        });
    }
});

// toggle header menu
$('.js-sandwich-button').on('click', function() {
    var $this = $(this),
        $headerMenu = $('.js-header-menu'),
        $headerMenuContent = $('.js-header-menu-content');
    if ( $this.hasClass('active') ) {
        $this.removeClass('active');
        $headerMenuContent.removeClass('active');
        $headerMenu.stop(true, true).slideUp('fast');
    }
    else {
        $this.addClass('active');
        $headerMenu.stop(true, true).slideDown('fast', function(){
            $headerMenuContent.addClass('active');
        });
    }
});

// Tabs
$('.js-tabs-nav-button').on('click', function(){
    var $this = $(this),
        $tabs = $this.parents('.js-tabs'),
        $tabsActiveButton = $tabs.find('.js-tabs-nav-button.active'),
        $tabItem = $tabs.find('.js-tabs-item');
    
    if ( !$this.hasClass('active') ) {
        $tabsActiveButton.removeClass('active');
        $this.addClass('active');
        $tabItem.stop(true, true).slideUp();
        $tabItem.eq($this.index()).stop(true, true).slideDown();
    }
});